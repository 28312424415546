import MDinput from '@/components/MDinput';
import { validatorRequire, validatorRequireNumber } from '@/utils/validators';
import MultiLanguageInput from '@/components/DSE/MultiLanguageInput';
import Switch from '@/components/DSE/Switch';
import NumericInput from '@/components/DSE/NumericInput';
import Select from '@/components/DSE/Select';

export function getFormDefinition(vue) {
  return {
    // tab 1
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    onlineYN: {
      type: Switch,
      defaultValue: false,
      props: {
        activeText: 'common.yes',
        inactiveText: 'common.no'
      }
    },
    position: {
      type: NumericInput,
      defaultValue: 0,
      props: {
        type: 'number',
        precision: 0,
        caption: 'common.position'
      }
    },
    template: {
      type: Select,
      defaultValue: '',
      props: {
        caption: 'page.template',
        valueField: 'code',
        labelField: 'description',
        loading: vue.$store.getters['option/isLoading'],
        options: vue.$store.getters['option/loadedOptions']('template'),
        required: true
      },
      rules: [{ validator: validatorRequireNumber }]
    },
    inFooterMenuYN: {
      type: Switch,
      defaultValue: false,
      props: {
        caption: 'page.inFooterMenu',
        activeText: 'common.yes',
        inactiveText: 'common.no'
      }
    },
    title: {
      type: MultiLanguageInput,
      props: {
        maxlength: 255,
        caption: 'common.title'
      }
    },
    content: {
      type: MultiLanguageInput,
      props: {
        type: 'textarea',
        caption: 'common.content'
      }
    },
    shortDescription: {
      type: MultiLanguageInput,
      props: {
        maxlength: 255,
        caption: 'page.shortDescription'
      }
    },
    keywords: {
      type: MultiLanguageInput,
      props: {
        maxlength: 255,
        caption: 'page.keywords'
      }
    },
    shortcut: {
      type: MultiLanguageInput,
      props: {
        maxlength: 255,
        caption: 'common.shortcut'
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return {
    ...item
  };
}
